<template>
    <div class="level-chart-main">
        <div class="level-chart" v-if="hasChart">
            <v-container fluid>
                <v-row no-getters class="level-chart-header">
                    <v-col cols="2">
                        <img
                            :src="srcTimerImage()"
                            class="level-chart-icon"
                            :style="bindStyle"
                        />
                    </v-col>
                    <v-col cols="5">
                        <div class="level-chart-title" :style="bindStyle">
                            CO2 Equalizer
                        </div>
                    </v-col>
                    <v-col cols="5">
                        <div class="level-chart-subtitle" :style="bindStyle">
                            90分前～<br />現在推移
                        </div>
                    </v-col>
                </v-row>
                <v-row class="level-cahrt-content" no-gutters>
                    <v-col v-for="(co2c, i) in getDataList()" :key="i">
                        <img
                            :src="strategy.srcChartImage(co2c)"
                            class="level-chart-img"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="level-chart-footer" :style="bindStyle">
                    <v-col class="first" v-for="(time, i) in getTimeList()" :key="i">
                        <span class="time">{{ time }}</span>
                    </v-col>
                </v-row>
                <v-row no-gutters class="level-chart-footer" :style="bindStyle">
                    <hr class="line" />
                    <v-col class="second" v-for="i in 6" :key="i">
                        <div class="circle" />
                    </v-col>
                    <v-col class="second">
                        <span>現在</span>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import createDetail from "../store/model.detail";

const vm = {
    mixins: [mixinApp, mixinDetail],
    computed: {
        bindStyle() {
            return this.strategy.bindStyle({}, this.detail, false);
        },
        hasChart() {
            return this.user && this.summary && this.summary.isNotEmpty();
        },
    },
    methods: {
        getCurrentCo2c() {
            const ret = this.getDataList();

            return ret[ret.length - 1];
        },
        createCurrentDetail() {
            return createDetail({ co2c: this.getCurrentCo2c() });
        },
        getDataList() {
            const len = 7;
            const list = this.detail.co2cSimpleList;

            while (list.length < len) {
                list.unshift(null);
            }

            return list;
        },
        getDataListCount() {
            return this.getDataList().length;
        },
        srcTimerImage() {
            return this.strategy.srcTimerImage(this.createCurrentDetail());
        },
        getTimeList() {
            return [90, 75, 60, 45, 30, 15, null];
        },
    },
};

export default vm;
</script>
<style scoped>
.level-chart-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

.level-chart-img {
    height: 10vh;
    width: 100%;
    padding: 2px;
}

.level-chart-header {
    display: flex;
    align-items: center;
}

.level-chart-content {
    height: 10vh;
}

.level-chart-icon {
    height: 3vh;
    width: 3vh;
}

.level-chart-title {
    font-size: 1vw;
    text-align: left;
    white-space: nowrap;
    margin-left: -0.5vw;
    color: var(--ventilation-level-color);
    transform: var(--card-span-transform);
}

.level-chart-subtitle {
    font-size: xx-small;
    text-align: right;
    white-space: nowrap;
}

.level-chart-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
}

.level-chart-footer .first,
.level-chart-footer .second {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.level-chart-footer .first .time {
    display: block;
    font-size: x-small;
}

.level-chart-footer .line {
    position: absolute;
    display: block;
    height: 2px;
    width: 85%;
    background-color: white;
}

.level-chart-footer .second .circle {
    z-index: 10;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: white;
}

.level-chart-footer .second span {
    display: block;
    font-size: x-small;
    text-align: center;
    color: var(--chart-color);
}
</style>
