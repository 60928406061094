<template>
    <div class="chart" :style="bindStyle">
        <GChart
            v-if="hasChart"
            type="LineChart"
            :data="chartData"
            :options="chartOption"
        />
    </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";

const Ratio = 0.15;
const vm = {
    mixins: [mixinApp, mixinDetail],
    components: {
        GChart,
    },
    computed: {
        bindStyle() {
            return {
                "--chart-height": this.chartHeight + "px",
            };
        },
        hasChart() {
            return (
                this.hierarchy &&
                this.summary &&
                this.detail &&
                this.detail.co2cSimpleList.length
            );
        },
        minMax() {
            return this.summary.getMinMaxCo2c(this.hierarchy);
        },
        chartHeight() {
            return this.innerHeight * Ratio;
        },
        chartData() {
            const ret = [["hour", "co2c", { role: "style" }]];

            if (!this.detail) {
                return ret;
            }

            for (const co2c of this.detail.co2cSimpleList) {
                ret.push(["hour", co2c, this.strategy.getChartColor(co2c)]);
            }

            return ret;
        },
        chartOption() {
            return {
                height: this.chartHeight,
                width: this.innerWidth * Ratio,
                lineWidth: 3,
                backgroundColor: "transparent",
                baselineColor: "none",
                legend: { position: "none" },
                tooltip: { trigger: "none" },
                chartArea: {
                    height: "100%",
                    width: "100%",
                },
                gridlines: {
                    color: "none",
                },
                vAxis: {
                    textPosition: "none",
                    gridlines: {
                        color: "transparent",
                    },
                    viewWindow: {
                        min: this.minMax.min,
                        max: this.minMax.max,
                    },
                },
                hAxis: {
                    textPosition: "none",
                    gridlines: {
                        color: "transparent",
                    },
                },
            };
        },
    },
};

export default vm;
</script>

<style scoped>
.chart {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--chart-height);
}
</style>
