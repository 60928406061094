<template>
    <div :style="bindStyle">
        <canvas :id="canvasID" class="canvas-chart"></canvas>
    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import mixinSensor from "../store/mixin.sensor";
import constant from "../store/constant";
import models from "../store/models";

const vm = {
    mixins: [mixinApp, mixinSensor, mixinDetail],
    data() {
        return {
            canvasHeight: 300,
            canvasWidth: 300,
        };
    },
    props: {
        propRotation: false,
        propSensor: false,
        propDetail: 0,
    },
    computed: {
        bindStyle() {
            return {
            };
        },
        canvasID() {
            return this.propSensor.sensorNumber + "-canvas";
        },
        sensor() {
            return this.propDetail;
        },
        searchUnit() {
            return this.propSearchUnit;
        },
        sensorData() {
            return this.summaryFloor.detailList[this.sensorNumberList.indexOf(this.propSensor.sensorNumber)];
        },
        rotationNo() {
            return this.propRotation.no;
        },
        getType() {
            return this.getParamType(this.propRotation.no).type;
        },
        getData() {
            var sensorData = this.sensorData;
            var obj = {};
            var datas;
            var vAxis;
            var type = this.getType;
            if (type == "co2c") {

                datas = sensorData.co2cSimpleList;
                vAxis = {
                    ticks: [0,500,1000,1500],
                    stat1: this.hierarchy.lowerYellow,
                    stat2: 1000,
                    unit: "ppm"
                }
                obj.vAxis = vAxis;
            } else if (type == "temp") {

                // tempSimpleList: [19.1, null, null, null]
                datas = sensorData.tempSimpleList;
                vAxis = {
                    ticks: [0,6,11.5,18,22.5,28,33.5,39],
                    // ticks: [6,11.5,18,22.5,28,33.5],
                    stat1: 18,
                    stat2: 28,
                    unit: "℃"
                }
                obj.vAxis = vAxis;

            } else if (type == "humi") {

                datas = sensorData.humiSimpleList;
                vAxis = {
                    ticks: [0,10,20,30,40,50,60,70,80,90,100],
                    // ticks: [10,20,30,40,50,60,70,80,90],
                    stat1: 40,
                    stat2: 70,
                    unit: "%"
                }
            }

            obj.datas = datas;
            obj.vAxis = vAxis;
            return obj;
        },
        getChartData: function() {

            var obj = this.getData;
            var ret = this.getUnits(obj.datas, 90, "min");

            var chart = {
                datas: obj.datas,
                adjust: 10,
                vAxis: obj.vAxis,
                hAxis: {
                    ticks: ret.ticks,
                    ticks2: ret.ticks2
                }
            }
            return chart;
        },
    },
    methods: {
        createSearchCondition() {
            const ret = models.createSearchCondition();

            ret.searchUnit = constant.searchUnitFloor();
            ret.userKind = this.user.userKind;
            ret.hierarchyCode = this.hierarchy.hierarchyCode;

            return ret;
        },
        getTicks() {

            var ticks = [];
            for (var i = 0; i <= 5; i++) {
                ticks.push(i)
            }
            return ticks;
        },
        replaceLimitDatas(datas, ticks) {

            var ret = [];
            var min = ticks[0]
            var max = ticks[ticks.length-1]
            for (var i = 0; i < datas.length; i++) {

                if (datas[i] > max) {
                    ret.push(max);
                } else if (datas[i] < min) {
                    ret.push(min);
                // } else if (i >= 1) {
                // } else if (i == 0) {
                // } else if (i == 1) {
                // } else if (i == 2) {
                // } else if (i == datas.length - 1) {
                    // ret.push(null);
                } else {
                    ret.push(datas[i]);
                }
            }
            return ret;
        },
        getUnits(datas, max, unit) {

            var step = max / (datas.length - 1);
            var ticks = [];
            var ticks2 = [];
            var ret = {
                ticks,
                ticks2
            }
            for (var i = datas.length; i >= 1; i--) {

                if (i == 1) {
                    ticks.push("現在");
                    ticks2.push("");
                } else {
                    ticks.push(step * (i - 1));
                    ticks2.push(unit);
                }
            }
            return ret;
        },
        getIndex(ticks, target) {

            var ret = {}
            var index = 0;
            for (var i = 0; i <= ticks.length; i++) {

                //一番目
                if (i == 0) {

                    if (0 <= target && target <= ticks[i+1]) {
                        index = 0;
                    }
                //最後
                } else if (i == ticks.length) {

                    if (target >= ticks[i-1]) {
                        index = ticks.length - 1;
                    }
                } else {
                    if (ticks[i-1] <= target && target < ticks[i+1]) {
                        index = i;
                        break;
                    }
                }

            }
            var val = ticks[index];
            ret.index = index;
            ret.val = val;
            return ret;
        },
        isItem(obj) {
            return (obj !== undefined && obj !== null && obj != "")
        },
        //X軸
        canvasH(ctx, req) {

            var hAxis = req.hAxis;
            if (hAxis===undefined || hAxis===null) return;

            var textAlign = (hAxis.styles && this.isItem(hAxis.styles.align)) ? hAxis.styles.align : "center";
            var textBaseline = (hAxis.styles && this.isItem(hAxis.styles.baseline)) ? hAxis.styles.baseline : "top";
            var color = (hAxis.styles && this.isItem(hAxis.styles.color)) ? hAxis.styles.color : "#fff";

            ctx.beginPath();
            ctx.textAlign = textAlign;
            ctx.textBaseline = textBaseline;
            ctx.strokeStyle = color;
            ctx.fillStyle = color;
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#808080";

            //横軸文字
            for (var i = 0; i < hAxis.ticks.length; i++) {

                var txt = hAxis.ticks[i];
                var txt2 = hAxis.ticks2[i];
                var tX = (i * hAxis.pich) + hAxis.space + req.adjust;
                var tY = req.height + req.adjust + 2;
                ctx.font = "18px Barlow Semi Condensed";
                if (i == req.width/hAxis.pich - 1) {
                    ctx.font = "16px Noto Sans JP";
                } else {
                    ctx.font = "18px Barlow Semi Condensed";
                }
                // if (this.isSPByWidth) {
                    if (i == req.width/hAxis.pich - 1) {
                        ctx.font = "12px Noto Sans JP";
                    } else {
                        ctx.font = "14px Barlow Semi Condensed";
                    }
                // }

                ctx.fillText(txt, tX, tY + 2);
                ctx.font = "14px Barlow Semi Condensed";
                // if (this.isSPByWidth) {
                    ctx.font = "10px Barlow Semi Condensed";
                // }

                //単位
                ctx.fillText(txt2, tX, tY + 16);
                //縦線(テストで表示)
                // ctx.moveTo(tX, 0);
                // ctx.lineTo(tX, req.height+req.adjust);
            }
            ctx.stroke();

            //タイトルの区切り
            ctx.beginPath();
            ctx.setLineDash([]);
            var mX = 0;
            var mY = req.height + req.adjust - 2;
            ctx.moveTo(mX, mY);
            var lX = req.width + req.hAxis.space;
            var lY = mY;
            ctx.lineTo(lX, lY);
            ctx.stroke();
        },
        //Y軸
        canvasV(ctx, req) {

            var vAxis = req.vAxis;
            if (vAxis===undefined || vAxis===null) return;

            var textAlign = (vAxis.styles && this.isItem(vAxis.styles.align)) ? vAxis.styles.align : "end";
            var textBaseline = (vAxis.styles && this.isItem(vAxis.styles.baseline)) ? vAxis.styles.baseline : "middle";
            var color = (vAxis.styles && this.isItem(vAxis.styles.color)) ? vAxis.styles.color : "#fff";

            ctx.textAlign = textAlign;
            ctx.textBaseline = textBaseline;
            ctx.strokeStyle = color;
            ctx.fillStyle = color;

            var vh = req.vAxis.pich;
            for (var i = 0; i < req.vAxis.ticks.length; i++) {

                var vn = req.vAxis.ticks[i];
                var unit = req.vAxis.unit;
                var left = req.vAxis.space - req.adjust;
                var top = req.height - (vh * (i + 1)) + req.vAxis.space + (req.adjust / 4);
                var strokeStyle = "#808080";
                var lineWidth = 1.4;

                //Y軸平行線
                ctx.beginPath();
                ctx.setLineDash([1.5, 1.5]);

                var isLine = true;
                //縦軸文字
                if (vn == req.vAxis.stat1 || (req.vAxis.stat2 && vn == req.vAxis.stat2)) {
                    lineWidth = 1.8;
                } else {
                    isLine = false;
                    lineWidth = 0;
                    vn = "";
                    unit = "";
                }

                //数字
                //SP
                // if (this.isSPByWidth) {
                    ctx.font = "14px Barlow Semi Condensed";
                    //co2c
                    if (this.getParamType(this.propRotation.no).type == "co2c") {
                        ctx.fillText(vn, left+2, top-4);
                    } else {
                        ctx.fillText(vn, left-8, top-4);
                    }
                    // console.log("chart size:",req.height,req.width);
                    // console.log("chart fillText:",vn,unit,left,top);
                //PC
                // } else {
                //     ctx.font = "18px Barlow Semi Condensed";
                //     //co2c
                //     if (this.getParamType(this.propRotation.no).type == "co2c") {
                //         ctx.fillText(vn, left+2, top-4);
                //     } else {
                //         ctx.fillText(vn, left-10, top-4);
                //     }
                // }

                //単位
                //SP
                // if (this.isSPByWidth) {
                    ctx.font = "10px Barlow Semi Condensed";
                    //co2c
                    if (this.getParamType(this.propRotation.no).type == "co2c") {
                        ctx.fillText(unit, left, top+5);
                    } else {
                        ctx.fillText(unit, left+2, top-3);
                    }
                // } else {
                //     ctx.font = "14px Barlow Semi Condensed";
                //     //co2c
                //     if (this.getParamType(this.propRotation.no).type == "co2c") {
                //         ctx.fillText(unit, left, top+10);
                //     } else {
                //         ctx.fillText(unit, left+4, top-2);
                //     }
                // }

                if (isLine) {

                    ctx.lineWidth = lineWidth;
                    ctx.strokeStyle = strokeStyle;
                    var mX = req.vAxis.space;
                    var mY = (vh * i) + req.vAxis.space - req.vAxis.pich;
                    ctx.moveTo(mX, mY);
                    var lX = mX + req.width;
                    var lY = mY;
                    if (i != 0) {
                        ctx.lineTo(lX, lY);
                    }
                }

                ctx.stroke();
            }
            // //1500ppm~を最左上部に記載 
            // if (Math.max(...req.datas) >= 1500) {
            //     ctx.font = "14px Barlow Semi Condensed";
            //     ctx.fillText("1500 ～", left+20, 25);
            //     ctx.font = "10px Barlow Semi Condensed";
            //     ctx.fillText("ppm", left+2, 25+9);
            // }
        },
        //折れ線グラフ
        lineChart(req, init) {

            var canvas;
            if (init === true) {

                canvas = this.createCanvas();
                //canvas = this.getCanvas();
            } else {

                canvas = this.getCanvas();
            }

            //canvas描画
            var ctx = canvas.getContext("2d");
            if (!canvas || !canvas.getContext) { return false; }
            //Y
            var vspace = (req.vAxis && this.isItem(req.vAxis.space)) ? req.vAxis.space : 45;
            var vticks = (req.vAxis && this.isItem(req.vAxis.ticks)) ? req.vAxis.ticks : "";
            var vunit = (req.vAxis && this.isItem(req.vAxis.unit)) ? req.vAxis.unit : "";
            var vstat1 = (req.vAxis && this.isItem(req.vAxis.stat1)) ? req.vAxis.stat1 : "";
            var vstat2 = (req.vAxis && this.isItem(req.vAxis.stat2)) ? req.vAxis.stat2 : "";


            //X
            var hspace = (req.hAxis && this.isItem(req.hAxis.space)) ? req.hAxis.space : 45;
            var hticks = (req.hAxis && this.isItem(req.hAxis.ticks)) ? req.hAxis.ticks : "";
            var hticks2 = (req.hAxis && this.isItem(req.hAxis.ticks2)) ? req.hAxis.ticks2 : "";
            var hunit = (req.hAxis && this.isItem(req.hAxis.unit)) ? req.hAxis.unit : "";

            //データ領域
            var height = this.canvasHeight;
            var canvasHeight = (this.isItem(height)) ? height : 300;
                canvasHeight = canvasHeight - hspace;
            var vpich = (canvasHeight / vticks.length);

            var width = this.canvasWidth;
            var canvasWidth = (this.isItem(width)) ? width : 300;
                canvasWidth = canvasWidth - vspace;

            var hpich = (canvasWidth / hticks.length);

            var datas = this.replaceLimitDatas(req.datas, req.vAxis.ticks);
            var adjust = req.adjust;
            // if (this.isSPByWidth) {
                vspace = (vspace / 1.5);
                hspace = (hspace / 1.5);
                adjust = (adjust / 2);
            // }
            var ret = {
                datas: datas,
                adjust: adjust,
                canvas: canvas,
                ctx: ctx,
                height: canvasHeight,
                width: canvasWidth,
                title: req.title,
                vAxis: {
                    space: vspace,
                    ticks: vticks,
                    unit: vunit,
                    pich: vpich,
                    stat1: vstat1,
                    stat2: vstat2
                },
                hAxis: {
                    space: hspace + adjust,
                    ticks: hticks,
                    ticks2: hticks2,
                    unit: hunit,
                    pich: hpich
                }
            }

            ctx.beginPath();
            ctx.fillStyle = "#2F2F2F";
            ctx.fillRect(0, 0, canvasWidth + hspace + adjust, canvasHeight + adjust);

            //X軸
            this.canvasH(ctx, ret);

            //Y軸
            this.canvasV(ctx, ret);

            var vp = ret.vAxis.pich;
            var hG = ret.hAxis.space + ret.adjust;
            var cw = ret.height + ret.vAxis.space;

            // line
            var lX = 0;
            var lY = 0;
            // var strokeStyle;
            // var fillStyle;
            for (var i = 0; i < ret.datas.length; i++) {

                ctx.lineJoin = "round";
                ctx.lineWidth = 4;

                var arr = this.getIndex(ret.vAxis.ticks, ret.datas[i]);
                var level = (arr.val / arr.index);
                    level = Math.floor( level * Math.pow(10, 1)) / Math.pow(10, 1);

                var mX =  (i * ret.hAxis.pich) + hG;
                    mX = Math.round(mX);
                var mY =  cw - (ret.datas[i] / level * vp) - vp;
                    mY = Math.round(mY);

                //色
                var strokeStyle = this.getStatus(this.propRotation.no, this.user.isUser(), ret.datas[i]).infos.code;
                var fillStyle = this.getStatus(this.propRotation.no, this.user.isUser(), ret.datas[i]).infos.code

                ctx.beginPath();
                ctx.strokeStyle = strokeStyle;
                ctx.fillStyle = fillStyle;
                ctx.setLineDash([]);

                //データがある
                if (ret.datas[i]) {

                    //前のデータがない場合は無視
                    if (lX != 0 && lY != 0) {

                        //次のデータがないかつ最後じゃない
                        if (!ret.datas[i+1] && i != ret.datas.length - 1) {
                            this.createPointer(ctx, mX, mY);
                        //前のデータがない
                        } else if (!ret.datas[i-1]) {
                            this.createPointer(ctx, lX, lY);
                        }
                        ctx.moveTo(mX, mY);
                    }

                    //1つ目は線不要
                    if (i != 0) {
                        ctx.lineTo(lX, lY);
                    }

                    //最後だけ矢印
                    if (i == ret.datas.length - 1) {

                        if (
                            ret.datas[i-1] === null &&
                            ret.datas[i-2] === null &&
                            ret.datas[i-3] === null
                        ) {
                            this.createCanvasArrow(ctx, lX, mY, mX, mY);
                        } else {
                            this.createCanvasArrow(ctx, lX, lY, mX, mY);
                        }
                    }

                    lX = mX;
                    lY = mY;

                //ない
                } else {

                    if (i != 0) {
                        mY = lY;
                        lX = mX;
                    } else {
                        var pLen = i + 1;
                        if (ret.datas[pLen]) {
                            ctx.strokeStyle = this.getStatus(this.propRotation.no, this.user.isUser(), ret.datas[pLen]).infos.code;

                            var p_arr = this.getIndex(ret.vAxis.ticks, ret.datas[pLen]);
                            var p_level = (p_arr.val / p_arr.index);
                                p_level = Math.floor( p_level * Math.pow(10, 1)) / Math.pow(10, 1);
                            var pX =  ((pLen) * ret.hAxis.pich) + hG;
                                pX = Math.round(pX);
                            var pY =  cw - (ret.datas[pLen] / p_level * vp) - vp;
                                pY = Math.round(pY);
                            this.createPointer(ctx, pX, pY);
                        }
                    }
                }
                ctx.stroke();
            }
            return ret;
        },
        //ポインター
        createPointer(ctx, x, y, type) {

            // circle
            // square
            if (type == "square") {
                ctx.fillRect(x-4,y-4,8,8);
            } else {
                ctx.arc(x,y,2,Math.PI,-360*Math.PI,true);
            }
        },
        //矢印
        createCanvasArrow(ctx, lX, lY, mX, mY) {

            var arrowlen = 14;
            var dx = mX - lX;
            var dy = mY - lY;
            //2点間の角度を求める関数
            var angle = Math.atan2(dy, dx);
            ctx.moveTo(lX, lY);
            ctx.lineTo(mX, mY);
            //角度のコサインA
            ctx.lineTo(mX - arrowlen * Math.cos(angle - Math.PI / 4), mY - arrowlen * Math.sin(angle - Math.PI / 4));
            ctx.moveTo(mX, mY);
            //Aの逆
            ctx.lineTo(mX - arrowlen * Math.cos(angle + Math.PI / 4), mY - arrowlen * Math.sin(angle + Math.PI / 4));
        },
        createCanvas() {

            var canvas = this.getCanvas();
            this.canvasHeight = canvas.clientHeight;
            this.canvasWidth = canvas.clientWidth < 180 ? 180 : canvas.clientWidth;
            canvas.height = this.canvasHeight;
            canvas.width = this.canvasWidth;
            
            return canvas;
        },
        getCanvas() {

            var canvas = document.getElementById(this.canvasID);
            canvas.height = this.canvasHeight;
            canvas.width = this.canvasWidth;
            return canvas;
        },
        loadChart(init) {
            this.lineChart(this.getChartData, init);
        },
    },
    mounted() {
        this.loadChart(true);
    },
    created() {
    },
    beforeDestroy() {
    },
    updated() {
        this.loadChart(false);
    },
    watch: {
        rotationNo() {
            this.loadChart(false);
        }
    },
};
export default vm;
</script>

<style>
@import '../assets/css/chart.min.css';
</style>