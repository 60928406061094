import { render, staticRenderFns } from "./RankingChartComponent.vue?vue&type=template&id=05ae12ba&scoped=true&"
import script from "./RankingChartComponent.vue?vue&type=script&lang=js&"
export * from "./RankingChartComponent.vue?vue&type=script&lang=js&"
import style0 from "./RankingChartComponent.vue?vue&type=style&index=0&id=05ae12ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ae12ba",
  null
  
)

export default component.exports