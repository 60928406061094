<template>
    <v-main>
        <div
            class="card"
            :class="bindCard"
            @click="
                transitionSensor(
                    user,
                    summary,
                    detail.sensor,
                    'ranking',
                    clickable,
                    propArea,
                    propRotation.no
                )
            "
            v-if="sensor.location"
            ref="card"
            :style="bindStyle"
        >
            <div class="location ranking_header">

                <span class="ranking_sensorName">{{ sensor.location }}</span>

                <span class="ring-sensor">
                    <img
                        v-if="ringSensorValid"
                        class="icon-ring-sensor"
                        src="../assets/icon-ring-sensor-on.png"
                    />
                    <img
                        v-else-if="doNotDisturbSensorValid"
                        class="icon-ring-sensor"
                        src="../assets/icon-ring-sensor-off.png"
                    />
                </span>
            </div>

            <div class="ranking_sensor_contents">

                <div class="ranking_sensorContents_Main">

                    <div class="ranking_sub sub1">
                        <div class="icon_area">
                            <img
                                :class="this.setSubs(rotation.no, 1).mode.class"
                                :src="this.setSubs(rotation.no, 1).mode.image"
                                :style="bindStyle"
                            />
                        </div>
                        <div class="disp_area">
                            <span class="disp" :class="this.setSubs(rotation.no, 1).mode.class" v-html="this.setSubs(rotation.no, 1).disp"></span>
                        </div>

                        <div class="face">
                            <img
                                class="face-image"
                                v-if="status.infos.src_color != null"
                                :src="status.infos.src_color"
                                :style="bindStyle"
                            />
                        </div>
                    </div>
                </div>

                <div class="ranking_sensorContents_Sub">
                    <div class="ranking_sub sub2">
                        <div class="icon_area">
                            <img
                                :class="this.setSubs(rotation.no, 2).mode.class"
                                :src="this.setSubs(rotation.no, 2).mode.image"
                                :style="bindStyle"
                            />
                        </div>
                        <div class="disp_area">
                            <span class="disp" :class="this.setSubs(rotation.no, 2).mode.class" v-html="this.setSubs(rotation.no, 2).disp"></span>
                        </div>
                        <div class="icon_area">
                            <img
                                :class="this.setSubs(rotation.no, 3).mode.class"
                                :src="this.setSubs(rotation.no, 3).mode.image"
                                :style="bindStyle"
                            />
                        </div>
                        <div class="disp_area">
                            <span class="disp" :class="this.setSubs(rotation.no, 3).mode.class" v-html="this.setSubs(rotation.no, 3).disp"></span>
                        </div>
                    </div>

                </div>

                <div class="message" ref="message" >
                    <div class="message-icon">
                        <img
                            v-if="
                                setMessage.infos.message &&
                                strategy.enabled() &&
                                setMessage.infos.image != null
                            "
                            class="icon icon-excl"
                            :src="setMessage.infos.image"
                        />
                    </div>
                    <div class="message-content" v-if="setMessage.infos.image != null">
                        <span>{{ setMessage.infos.message }}</span>
                    </div>
                </div>

                <div class="chart-area" v-if="!isIphone || isSensor">
                    <p class="chart-guide">直近90分間の{{ this.setSubs(rotation.no, 1).mode.caption }}推移</p>
                    <div class="chart-box">
                        <chart-component
                            :propRotation="rotation"
                            :propSensor="sensor"
                            :propDetail="detail"
                            @canvas="canvas=$event"
                        />
                    </div>
                </div>
            </div>
        </div>
    </v-main>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import mixinSensor from "../store/mixin.sensor";
import RankingChartComponent from "../components/RankingChartComponent";
import LevelChartComponent from "../components/LevelChartComponent";
import ChartComponent from "../components/ChartComponent";
import constant from "../store/constant";
//import chroma from "chroma-js";

const vm = {
    mixins: [mixinApp, mixinDetail, mixinSensor],
    props: {
        propClickable: false,
        propIsSensor: false,
        propRotation: false,
    },
    data() {
        return {
            messageWidth: 0,
        };
    },
    components: {
        RankingChartComponent,
        LevelChartComponent,
        ChartComponent
    },
    computed: {
        bindStyle() {
            return this.strategy.bindStyle(
                {
                    "--location-font-size": this.getLocationFontSize() + "px",
                    "--message-font-size": this.getMessageFontSize() + "px",
                    "--card-height": this.getCardHeight() + "px",
                    "--status-code": this.status.infos.code,
                    "--status-code-alpha": this.backGroundStatus(),
                },
                this.detail,
                this.clickable
            );
        },
        bindCard() {
            return "card-" + this.status.infos.name;
        },
        clickable() {
            return this.propClickable;
        },
        isSensor() {
            return this.propIsSensor;
        },
        ringSensorValid() {
            return this.isEnableRing && this.isRingSensorAtTime(this.sensor.sensorNumber);
        },
        doNotDisturbSensorValid() {
            return this.isEnableRing && this.isDoNotDisturbSensor(this.sensor.sensorNumber);
        },
        rotation() {
            return this.propRotation;
        },
        status() {
            return this.getStatus(this.propRotation.no, this.user.isUser());
        },
        setMessage() {
            var ret = this.getMessage(this.status);
            return ret;
        },
    },
    methods: {
        getMessage(req) {
            req.infos.message = this.strategy.getMessage(this.detail);
            if (this.strategy.toDetail(this.detail).isEmpty()) {

                if (this.detail.errorReason == constant.grayNone()) {

                    //req.infos.message = "データが取得できていません";
                    req.infos.image = constant.setImage("icon_exclamation.png");
                }

                if (this.detail.errorReason == constant.grayOverflow()) {
                    //req.infos.message = "センサーの電源を抜き差ししてください";
                    req.infos.image = constant.setImage("icon_plug.png");
                }


            }
            return req;
        },
        geRed() {
            if (!(this.hierarchy && this.detail)) {
                return false;
            }

            return this.detail.co2c >= this.hierarchy.lowerRed;
        },
        getLocationFontSize() {
            const marginWidth = 0.8;
            const len = constant.getLength(this.sensor.location) + 2 || 1;
            const fontSizeWidth = (this.cardWidth * marginWidth) / len;
            const maxFontSize = this.cardHeight * 0.03;

            return fontSizeWidth > maxFontSize ? maxFontSize : fontSizeWidth;
        },
        getMessageFontSize() {
            const margin = 0.95;
            const messageLength = this.strategy.getMessage(this.detail).length > 11 ? this.strategy.getMessage(this.detail).length : 11; //適正に換気されています, そろそろ換気が必要です
            // const messageLength =
            //     this.strategy.getMessage(this.detail).length || 1;
            const maxFontSize = this.cardHeight * 0.06;
            const fontSizeWidth = Math.floor((this.messageWidth * margin) / messageLength);
            return fontSizeWidth > maxFontSize ? maxFontSize : fontSizeWidth;
        },
        getCardHeight() {
            if (this.propIsSensor) {
                return this.innerHeightCriteria * 75;
            }
            return this.innerHeightCriteria * (this.isIphone ? 55 : 75);
        },
        afterResize() {
            if (!this.sensor.location) {
                return;
            }
            this.messageWidth = this.$refs.message
                ? this.$refs.message.clientWidth
                : 0;
            this.cardWidth = this.$refs.card.clientWidth;
            this.cardHeight = this.$refs.card.clientHeight;
            this.checkIphone();
        },
        /**
         * 0 > co2c
         * 1 > temp
         * 2 > humi
         */
        setSubs(no, key) {
            var priority = this.getParamType(no).priority;
            var arr = this.getParamType(priority[key-1]);
            return arr;
        },
        // backGroundStatus(){
        // const col = chroma(this.status.infos.code).darken().saturate().hex();
        // return col;
        // },
        backGroundStatus(){
            var firstinitial = this.status.infos.code.substring(1, 3);
            var thirdinitial = this.status.infos.code.substring(3, 5);
            var fifthinitial = this.status.infos.code.substring(5, 7);    

            var firstdecimal = parseInt(firstinitial,16);
            var thirddecimal = parseInt(thirdinitial,16);
            var fifthdecimal = parseInt(fifthinitial,16);
            
            var firstresult = Math.floor(firstdecimal / 2);
            var thirdresult = Math.floor(thirddecimal / 2);
            var fifthresult = Math.floor(fifthdecimal / 2);
            
           
            var firsthex =  Number(firstresult).toString(16).padStart( 2, '0');
            var thirdhex =  Number(thirdresult).toString(16).padStart( 2, '0');
            var fifthhex =  Number(fifthresult).toString(16).padStart( 2, '0');

            const total = "#" + firsthex + thirdhex + fifthhex;
            return total         
        },
    },
    mounted() {
        this.$nextTick(function () {
            // ビュー全体がレンダリングされた後にのみ実行
            // タイトル幅を算出する際に利用
            this.afterResize();
        });
    },
};

export default vm;
</script>

<style scoped>
@import '../assets/css/ranking.min.css';
.card {
    cursor: var(--cursor);
    pointer-events: var(--pointer-events);
    height: var(--card-height);
    color: var(--card-font-color);
    background-color: var(--card-background-color);
    border-color: var(--status-code);
    border-style: var(--card-border-style);
}
.card span {
    transform: var(--card-span-transform);
}

.sub1 .disp {
    color: var(--status-code);
}

.message {
    /* color: var(--status-code); */
    color: var(--card-font-color);
}
</style>
